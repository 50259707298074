import { NoHydration } from 'solid-js/web';
import { BreadcrumbItem, Button, Container, Heading, HorizontalRule, Link, Page, Section } from '@troon/ui';
import { createAsync, useLocation } from '@solidjs/router';
import { Meta, Title } from '@solidjs/meta';
import { Hero } from '../../../components/hero/photo';
import { getConfigValue } from '../../../modules/config';
import { gql } from '../../../graphql';
import { cachedQuery } from '../../../graphql/cached-get';
import { FacilitiesByGrouping } from '../../../components/facility/by-grouping';
import { PictureSection } from '../../../components/layouts/picture-section';
import type { RouteDefinition } from '@solidjs/router';

export default function PrivePage() {
	const loc = useLocation();

	const data = createAsync(
		() =>
			getFacilities({
				slugs,
				featured: ['rio-verde-country-club'],
				// TODO: pull a random image?
				// [slugs[Math.floor(Math.random() * slugs.length)]!]
			}),
		{ deferStream: true },
	);

	return (
		<>
			<Title>Private Club Golf | Troon Privé Member Programs | Troon</Title>
			<Meta
				name="description"
				content="Full Members at Troon Privé clubs can play at other private clubs in the portfolio at preferred rates."
			/>

			<NoHydration>
				<Hero
					src={`${getConfigValue('IMAGE_HOST')}/digital/hero/private.jpg`}
					breadcrumbs={() => (
						<>
							<BreadcrumbItem href="/member-programs">Troon Privé Privileges</BreadcrumbItem>
							<BreadcrumbItem href={loc.pathname}>Private Club Golf</BreadcrumbItem>
						</>
					)}
				>
					<Heading as="h1">Private Club Golf</Heading>
					<p class="text-lg">
						Full Members at Troon Privé clubs can play at other private clubs in the portfolio at preferred rates.
					</p>
				</Hero>
			</NoHydration>

			<Container>
				<Page>
					<PictureSection src={data()?.featured.facilities[0]?.metadata?.hero?.url} loading="eager">
						<NoHydration>
							<Heading as="h2">Overview</Heading>
							<p>
								Full Members at Troon Privé clubs have the privilege to play at other private clubs in the portfolio at
								a preferred Troon Privé Member rate, cart and range balls included. Members are entitled to bring up to
								three guests that will play for the host clubs accompanied rates. Each golfer can play up to (6) rounds
								at any given facility in one calendar year. (This benefit is limited to (3) visits for clubs in the same
								geographic market when noted in the guidelines). Rounds are subject to the host clubs’ advance booking
								policy for unaccompanied guest/reciprocal play.
							</p>
							<p class="rounded bg-neutral-100 p-4 text-sm font-semibold">
								Please note that only qualified private club members may request tee times at participating private
								clubs within Troon Privé Privileges. If you have any questions about the program or are experiencing
								issues accessing these benefits, please contact your home club staff. Troon Privé clubs do not
								participate in Troon Rewards.
							</p>
							<p class="flex items-start gap-4">
								<Button as={Link} href="/member-programs/private-club-golf/request-a-tee-time" class="grow-0">
									Request a tee time
								</Button>
								<Button as={Link} href="#participating-clubs" appearance="secondary" class="grow-0">
									Participating clubs
								</Button>
							</p>
						</NoHydration>
					</PictureSection>

					<HorizontalRule />

					<Section id="participating-clubs">
						<Heading as="h2" class="mx-auto max-w-4xl text-center">
							Participating Clubs
						</Heading>
						<FacilitiesByGrouping facilities={data()?.facilities.facilities ?? []} />
					</Section>
				</Page>
			</Container>
		</>
	);
}

export const route = { info: { hasHero: true } } satisfies RouteDefinition;

const slugs = [
	'alpine-country-club-ri',
	'alpine-country-club-ut',
	'audubon-country-club',
	'austin-country-club',
	'bahia-beach-resort-golf-club',
	'ballenisles-country-club',
	'bay-creek',
	'bayside-resort-golf-club',
	'beechmont-country-club',
	'belfair',
	'blackstone-country-club-at-vistancia',
	'bookcliff-country-club',
	'boulder-ridge-golf-club',
	'boulders-resort-and-spa',
	'broken-top-club',
	'calvert-crossing-golf-club',
	'canebrake-country-club',
	'capital-canyon-club',
	'champion-hills',
	'chateau-golf-and-country-club',
	'cimarron-hills-golf-and-country-club',
	'colonial-country-club-fl',
	'columbia-country-club',
	'columbus-country-club',
	'comanche-trace',
	'contra-costa-country-club',
	'crystal-lake-country-club',
	'deering-bay-yacht-country-club',
	'eau-claire-golf-country-club',
	'echo-valley-country-club',
	'el-macero-country-club',
	'entrada-at-snow-canyon-country-club',
	'esplanade-golf-country-club',
	'esplanade-lakewood-ranch',
	'fairbanks-ranch-country-club',
	'fairwood-golf-country-club',
	'fargo-country-club',
	'fiddlers-creek',
	'fieldstone-golf-club',
	'flagstaff-ranch-golf-club',
	'gadsden-country-club',
	'hunting-creek-country-club',
	'kankakee-country-club',
	'kemper-lakes-golf-club',
	'knollwood-country-club-mi',
	'lake-valley-golf-club',
	'le-triomphe-golf-country-club',
	'links-at-perry-cabin',
	'loma-santa-fe-country-club',
	'manchester-country-club',
	'marin-country-club',
	'mill-creek-country-club',
	'naperville-country-club',
	'northriver-yacht-club',
	'ocean-club-at-hutchison-island',
	'ocean-edge-resort-golf-club',
	'old-overton-club',
	'oldfield-club',
	'oneida-golf-and-country-club',
	'parkland-golf-country-club',
	'pelican-lakes-resort-and-golf',
	'pga-national-resort',
	'phoenix-country-club',
	'pine-canyon-club',
	'pine-tree-country-club',
	'pinetop-country-club',
	'pronghorn-at-juniper-preserve',
	'raindance-national',
	'raintree-country-club',
	'raritan-valley-country-club',
	'red-hawk-golf-and-resort',
	'reserva-conchal-club',
	'rio-mar-country-club',
	'rio-verde-country-club',
	'riverton-pointe-golf-and-country-club',
	'salina-country-club',
	'santa-maria-golf-country-club',
	'santa-rosa-golf-and-beach-club',
	'seminole-legacy-golf-club',
	'silver-creek-valley-country-club',
	'silverado-resort-and-spa',
	'somerby-golf-club',
	'southern-trace-country-club',
	'springdale-golf-club',
	'sterling-grove-golf-country-club',
	'stonetree-golf-club',
	'talking-rock',
	'terravita-golf-club',
	'the-club-at-arrowcreek-2',
	'the-club-at-castlewood',
	'the-club-at-cordillera',
	'the-club-at-grand-del-mar',
	'the-club-at-hammock-beach',
	'the-club-at-porto-cima',
	'the-clubs-at-st-james',
	'the-country-club-of-colorado-at-cheyenne-mountain',
	'the-falls-club',
	'the-fountaingrove-club',
	'the-golf-club-at-lansdowne',
	'the-lodge-of-four-seasons',
	'the-peninsula-golf-country-club',
	'the-pga-czech-national-golf-course',
	'the-powder-horn-golf-club',
	'the-standard-club',
	'the-westin-la-paloma-resort-spa',
	'tiburon-golf-club',
	'tumble-brook-country-club',
	'legends-club',
	'wausau-country-club',
	'west-bay-club',
	'westchester-hills-golf-club',
	'westhaven-golf-club',
	'westmoreland-country-club',
	'wickenburg-ranch-golf-social-club',
	'woodbridge-golf-country-club',
	'yorba-linda-country-club',
];

const query = gql(`query priveFacilities($slugs: [String!], $featured: [String!]) {
	facilities: facilitiesV3(idOrSlugs: $slugs) {
		facilities {
			...FacilityByState
		}
	}
	featured: facilitiesV3(idOrSlugs: $featured) {
		facilities {
			name
			metadata {
				hero {
					url
				}
			}
		}
	}
}`);

const getFacilities = cachedQuery(query);
